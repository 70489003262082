import {
  Comment,
  type DirectiveBinding,
  type Slots,
  type VNode,
  vShow,
} from 'vue';

// transitionのためにwrapperが必要な場合、コンテンツのv-ifやv-showを引き継いだwrapperでラップしたコンテンツを返す
export const wrapElementTransparentlyForTransition = (
  slots: Slots,
  wrapperTag: string,
  wrapperProps: Record<string, any>
): (() => VNode[]) => {
  const child =
    slots.default?.().filter((s) => s.type !== Comment)[0] ?? undefined;

  const vIfValue = child !== undefined;
  if (vIfValue === false) {
    // v-if="false"
    return () => [];
  }

  const isVShowDirective = (
    directive: DirectiveBinding<any>
  ): directive is DirectiveBinding<boolean> => {
    return directive.dir === vShow && typeof directive.value === 'boolean';
  };
  const vShowDirective = child?.dirs?.find(isVShowDirective);
  if (vShowDirective !== undefined) {
    if (vShowDirective.value === false) {
      // v-show="false"
      vShowDirective.value = true;

      return () => [
        withDirectives(h(wrapperTag, wrapperProps, [child]), [[vShow, false]]),
      ];
    }
    // v-show="true"
    return () => [
      withDirectives(h(wrapperTag, wrapperProps, [child]), [[vShow, true]]),
    ];
  }

  // v-if="true"
  return () => [h(wrapperTag, wrapperProps, [child])];
};
